import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _format from 'date-fns/format';
import Tooltip from 'cccisd-tooltip';

import style from './style.css';

import IconWarn from 'cccisd-icons/bubble-notification';
import IconComment from 'cccisd-icons/bubble2';
import IconClose from 'cccisd-icons/cross2';

const FeedbackBanner = props => {
    const { data, low } = props;

    const [isExpanded, setIsExpanded] = useState(false);
    const [feedbackCount, setFeedbackCount] = useState(null);
    const [settings, setSettings] = useState({});

    useEffect(() => {
        setFeedbackCount(data.length);
        low
            ? setSettings({
                  id: 'low',
                  color: '#b4004e',
                  devTag: 'FeedbackLow',
                  tooltip:
                      'Open-ended feedback appears here when the [caregiver/youth] selects 0, “Not at All,” or 1, “Not Really,” for at least one survey item from an individual text',
                  title: 'Feedback for Low Scores',
              })
            : setSettings({
                  id: 'default',
                  color: '#45317e',
                  devTag: 'Feedback',
                  tooltip:
                      'Open-ended feedback appears here when the [caregiver/youth] selects 2, “Somewhat,” or 3, “Absolutely,” for all survey items from an individual text',
                  title: 'General Feedback',
              });
    }, []);

    const getActionText = () => {
        if (!isExpanded) {
            return `Show (${feedbackCount})`;
        }
        return <IconClose />;
    };

    const getText = () => {
        const res = data.map(fb => {
            return {
                value: fb.devTags[settings.devTag],
                date: _format(new Date(fb.completedDate), 'M/d/yyyy'),
            };
        });

        if (!isExpanded) {
            if (low) {
                return 'Show Feedback on Low Scores';
            }
            return 'Show Feedback';
        }
        return res.map((el, i) => {
            return (
                <div key={i}>
                    <i style={{ marginRight: '0.5em' }}>{el.date}</i>
                    {el.value}
                </div>
            );
        });
    };

    return (
        <Tooltip title={settings.tooltip} placement="top">
            <div
                className={style.banner}
                onClick={() => setIsExpanded(!isExpanded)}
                style={{ border: `2.5px solid ${settings.color}` }}
            >
                <div style={{ backgroundColor: settings.color }} className={style.iconWrapper}>
                    <div className={style.icon}>{low ? <IconWarn /> : <IconComment />}</div>
                </div>
                <div className={style.flexit}>
                    <div className={style.text}>
                        {!isExpanded && (
                            <strong style={{ color: settings.color }} className={style.title}>
                                {settings.title}
                            </strong>
                        )}
                        <div>{getText()}</div>
                    </div>
                    <strong className={style.count}>{getActionText()}</strong>
                </div>
            </div>
        </Tooltip>
    );
};

FeedbackBanner.propTypes = {
    data: PropTypes.array,
    low: PropTypes.bool,
};

export default FeedbackBanner;
