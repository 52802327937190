import React, { useRef } from 'react';
import Modal from 'cccisd-modal';
import axios from 'cccisd-axios';
import PropTypes from 'prop-types';
import Tooltip from 'cccisd-tooltip';
import notification from 'cccisd-notification';
import YouthForm from '../../../../components/Manage/YouthForm';

import IconPencil from 'cccisd-icons/pencil';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const EditYouthForm = props => {
    const modal = useRef();

    // rows are coming from each role's youth_tableMain.graphql
    const { row, loadData } = props;

    const onSubmit = async values => {
        const response = await axios.put(
            Boilerplate.route('api.nexus.pawn.update', {
                pawn: row['pawn.pawnId'],
            }),
            {
                pawnHash: row['pawn.pawnHash'],
                firstName: values.firstName,
                lastName: values.lastName,
                wrapId: values.wrapId,
                dateOfBirth: values.dateOfBirth,
                youngAdult: values.youngAdult,
                phone: values.phone,
                parentPawns: values.parentPawns,
                group: values.site,
            }
        );
        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }
        await loadData();
        notification('Youth edited.');
        await modal.current.close();
    };

    return (
        <Modal
            ref={modal}
            trigger={
                <Tooltip title="Edit Youth">
                    <button type="button" className="btn btn-xs btn-success">
                        <IconPencil />
                    </button>
                </Tooltip>
            }
            title={row['fields.wrapId'] ? `Edit ${row['fields.wrapId']}` : `Edit Youth`}
        >
            <YouthForm
                onSubmit={onSubmit}
                initialValues={{
                    firstName: row['fields.firstName'],
                    lastName: row['fields.lastName'],
                    wrapId: row['fields.wrapId'],
                    dateOfBirth: row['fields.dateOfBirth'],
                    youngAdult: row['fields.youngAdult'],
                    phone: row['user.phone'],
                    site: [row['parentGroup.site.group.groupId']],
                    careCoordinator: [row['parentRoles.careCoordinator.pawn.pawnId']],
                    caregiver: [row['parentRoles.caregiver.pawn.pawnId']],
                }}
                {...props}
            />
        </Modal>
    );
};

EditYouthForm.propTypes = {
    loadData: PropTypes.func,
    row: PropTypes.object,
};

export default EditYouthForm;
