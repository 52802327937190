import React from 'react';
import { Formik, Form, Field, CccisdInput, CccisdFieldWrapper } from 'cccisd-formik';
import Table from 'cccisd-graphql-table';
import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import sitesQuery from '../sites.graphql';
import careCoordinatorsQuery from '../careCoordinators.graphql';
import RequiredFieldLabel from '../RequiredFieldLabel';

const CaregiverForm = props => {
    const { onSubmit, initialValues } = props;

    const onHandleSubmit = async values => {
        values.wrapId = `${values.wrapId.replace(/\s/g, '')}@smartwrap.com`;
        values.careCoordinatorId = JSON.stringify(values.careCoordinator);
        values.site = values.site[0];

        await onSubmit(values);
    };

    const validate = values => {
        const errors = {};

        if (!values.wrapId) {
            errors.wrapId = 'Wrap ID must be unique to each caregiver and is required.';
        }
        if (!values.phone) {
            errors.phone = 'Phone number is required.';
        }
        if (values.phone && values.phone) {
            if (!/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(values.phone)) {
                errors.phone = 'Must be a valid phone number.';
            }
        }
        if (values.site.length < 1) {
            errors.site = 'Site is required.';
        }
        if (values.site.length > 1) {
            errors.site = 'You may select only 1 Site.';
        }
        if (values.careCoordinator < 1) {
            errors.careCoordinator = 'Care Coordinator is required.';
        }
        if (values.careCoordinator.length > 1) {
            errors.careCoordinator = 'You may select only 1 Care Coordinator.';
        }

        return errors;
    };

    const siteColumns = [
        {
            name: 'group.groupId',
            label: 'ID',
            hideInTable: true,
        },
        {
            name: 'group.label',
            label: 'Site',
            sort: true,
            filter: true,
        },
        {
            name: 'ancestorGroups.agency.group.label',
            label: 'Agency',
            sort: true,
            filter: true,
        },
        {
            name: 'ancestorGroups.organization.group.label',
            label: 'Organization',
            sort: true,
            filter: true,
        },
        {
            name: 'ancestorGroups.collaborator.group.label',
            label: 'Collaborator',
            sort: true,
            filter: true,
        },
    ];

    const careCoordinatorColumns = [
        {
            name: 'pawn.pawnId',
            label: 'ID',
            hideInTable: true,
        },
        {
            name: 'user.fullName',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        {
            name: 'parentRoles.supervisor.user.fullNameWithUsername',
            label: 'Supervisor',
            sort: true,
            filter: true,
        },
        {
            name: 'parentGroup.site.childRoles.siteAdmin.user.fullNameWithUsername',
            label: 'Site Admin',
            sort: true,
            filter: true,
        },
    ];

    return (
        <Formik onSubmit={e => onHandleSubmit(e)} validate={e => validate(e)} initialValues={initialValues}>
            {({ handleSubmit, values, setFieldValue }) => (
                <Form>
                    <Field
                        name="wrapId"
                        component={CccisdInput}
                        label={<RequiredFieldLabel isRequired>Wrap ID (must be unique):</RequiredFieldLabel>}
                    />
                    <Field name="firstName" component={CccisdInput} label="First Name:" />
                    <Field name="lastName" component={CccisdInput} label="Last Name:" />
                    <Field
                        name="phone"
                        country="US"
                        component={CccisdInput}
                        label={<RequiredFieldLabel isRequired>Phone Number:</RequiredFieldLabel>}
                    />
                    <Field name="site" enableReinitialize>
                        {({ field, form }) => {
                            return (
                                <CccisdFieldWrapper
                                    field={field}
                                    form={form}
                                    label={<RequiredFieldLabel isRequired>Site:</RequiredFieldLabel>}
                                >
                                    <Table
                                        query={sitesQuery}
                                        rowKey="group.groupId"
                                        columns={siteColumns}
                                        csvFilename="Sites.csv"
                                        showRowActions={false}
                                        rowSelectedCallback={selectedRows => {
                                            if (!_isEqual(selectedRows, field.value)) {
                                                setFieldValue('site', selectedRows);
                                                setFieldValue('careCoordinator', []);
                                            }
                                        }}
                                        selectedRows={initialValues.site}
                                        maxSelectableRows={1}
                                        rowActions={[
                                            {
                                                name: 'selectSite',
                                                title: 'Add to Site',
                                                action: () => {},
                                            },
                                        ]}
                                    />
                                </CccisdFieldWrapper>
                            );
                        }}
                    </Field>
                    {values.site && values.site.length === 1 && (
                        <Field name="careCoordinator" enableReinitialize>
                            {({ field, form }) => {
                                return (
                                    <CccisdFieldWrapper
                                        field={field}
                                        form={form}
                                        label={<RequiredFieldLabel isRequired>Care Coordinator:</RequiredFieldLabel>}
                                    >
                                        <Table
                                            query={careCoordinatorsQuery}
                                            rowKey="pawn.pawnId"
                                            columns={careCoordinatorColumns}
                                            csvFilename="Care Coordinators.csv"
                                            showRowActions={false}
                                            rowSelectedCallback={selectedRows => {
                                                if (!_isEqual(selectedRows, field.value)) {
                                                    setFieldValue('careCoordinator', selectedRows);
                                                }
                                            }}
                                            selectedRows={
                                                // if the site is edited, careCoordinator should be []
                                                !_isEqual(form.values.site, initialValues.site)
                                                    ? field.value
                                                    : initialValues.careCoordinator
                                            }
                                            graphqlVariables={{
                                                siteId: values.site[0],
                                            }}
                                            maxSelectableRows={1}
                                            rowActions={[
                                                {
                                                    name: 'selectCareCoordinator',
                                                    title: 'Link to Care Coordinator',
                                                    action: () => {},
                                                },
                                            ]}
                                        />
                                    </CccisdFieldWrapper>
                                );
                            }}
                        </Field>
                    )}

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '1em',
                        }}
                    >
                        <button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>
                            Submit
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

CaregiverForm.propTypes = {
    onSubmit: PropTypes.func,
    initialValues: PropTypes.object,
};

export default CaregiverForm;
