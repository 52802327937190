import React, { useState } from 'react';
import Table from 'cccisd-table';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from 'cccisd-tooltip';

import IconArrowRight from 'cccisd-icons/arrow-right20';
import IconFlag from 'cccisd-icons/flag';

import style from './style.css';

const DomainTable = props => {
    const [isExpanded, setIsExpanded] = useState(false);

    const { type, data, flagged, avg, tooltipText } = props;

    const columns = [
        {
            name: 'surveyItem',
            label: 'Survey Item',
            filter: true,
            width: '66%',
            setStyle: (value, row) => (row.score === 0 ? { backgroundColor: '#FFCCCB' } : {}),
        },
        {
            name: 'score',
            label: 'Response',
            class: 'text-center',
            sort: true,
            filter: true,
            width: '33%',
            tooltip: (
                <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                    <li>
                        0: Not at All{' '}
                        <span style={{ fontSize: '1.5em' }} role="img" aria-label="super sad">
                            ☹️
                        </span>
                    </li>
                    <li>
                        1: Not Really{' '}
                        <span role="img" aria-label="neutral">
                            😐
                        </span>
                    </li>
                    <li>
                        2: Somewhat{' '}
                        <span role="img" aria-label="smiley">
                            🙂
                        </span>
                    </li>
                    <li>
                        3: Absolutely{' '}
                        <span role="img" aria-label="super smiley">
                            😊
                        </span>
                    </li>
                </ul>
            ),
            setStyle: (value, row) => (value === 0 ? { backgroundColor: '#FFCCCB' } : {}),
        },
        {
            name: 'completedAt',
            label: 'Completed Date',
            class: 'text-center',
            sort: true,
            filter: true,
            filterSettings: { type: 'date' },
            setStyle: (value, row) => (row.score === 0 ? { backgroundColor: '#FFCCCB' } : {}),
        },
    ];

    return (
        <div
            className={classnames(style.card, {
                [style.expanded]: isExpanded,
            })}
            key={type}
        >
            <div
                className={classnames(style.cardHeader, {
                    [style.expanded]: isExpanded,
                })}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <div className={style.unexpandedCard}>
                    <div
                        className={classnames(style.arrow, {
                            [style.expanded]: isExpanded,
                        })}
                    >
                        <IconArrowRight />
                    </div>
                    <h3 className={style.type}>
                        <div className={style.flagged}>
                            {tooltipText ? <Tooltip title={tooltipText}>{type}</Tooltip> : type}
                            {flagged > 0 && (
                                <div className={style.flag}>
                                    <IconFlag spaceLeft spaceRight />
                                </div>
                            )}
                        </div>
                        <div style={avg < 1 ? { color: '#b4004e' } : {}}>Average: {avg}</div>
                    </h3>
                </div>
            </div>
            {isExpanded && (
                <div className={style.table}>
                    <Table
                        columns={columns}
                        data={data}
                        showPerPageOptions={false}
                        hideShowingRowsSummary
                        orderBy="completedAt"
                    />
                </div>
            )}
        </div>
    );
};

DomainTable.propTypes = {
    type: PropTypes.string,
    data: PropTypes.array,
    flagged: PropTypes.number,
    avg: PropTypes.number,
    tooltipText: PropTypes.string,
};

export default DomainTable;
