import { useQuery } from 'cccisd-react-query';
import { client } from 'cccisd-apollo';

import youthQuery from './youth.graphql';

const Fortress = window.cccisd && window.cccisd.fortress;

const isCareCoordinator = Fortress.user.acting.role.handle === 'careCoordinator';
const isSupervisor = Fortress.user.acting.role.handle === 'supervisor';

const fetchYouth = async () => {
    const response = await client.query({
        query: youthQuery,
        variables: {
            careCoordinatorFilter: isCareCoordinator
                ? {
                      AND: [
                          {
                              eq: {
                                  field: 'parentRoles.careCoordinator.pawn.pawnId',
                                  int: Fortress.user.acting.id,
                              },
                          },
                      ],
                  }
                : null,
            supervisorFilter: isSupervisor
                ? {
                      AND: [
                          {
                              eq: {
                                  field: 'parentRoles.careCoordinator.parentRoles.supervisor.pawn.pawnId',
                                  int: Fortress.user.acting.id,
                              },
                          },
                      ],
                  }
                : null,
        },
        fetchPolicy: 'network-only',
    });
    return response;
};

export const useGetYouthQuery = (key = 'youth', select, notifyOnChangeProps) =>
    useQuery([key], fetchYouth, { refetchOnWindowFocus: false });
