import _format from 'date-fns/format';

export default data => {
    const youthSatisfactionData = data.satisfaction;
    const youthSatisfactionQuestions = [
        {
            1: 'Thinking of the past month, I feel like my care coordinator and my Wraparound team understand me.',
        },
        {
            2: 'Thinking of the past month, I feel like my care coordinator and my Wraparound team know how to help me.',
        },
        { 3: 'Over the past month, I have been satisfied with my progress.' },
        { 4: 'Over the past month, I have been satisfied with how Wraparound is working for me.' },
    ];

    const youthOutcomesData = data.outcomes;
    const youthOutcomesQuestions = [
        {
            1: 'Over the past month, things have gotten better for me.',
        },
        {
            2: 'Over the past month, I have felt like there are adults in my life who will help me out when I need it.',
        },
        { 3: 'Over the past month, my overall mental health has been good.' },
        {
            4: 'Over the past month, I have felt like there are people in my life who care about me.',
        },
    ];

    const youthFeedback = data.progress.started && data.feedback;
    const youthFeedbackLow = data.progress.started && data.lowFeedback;

    // Caregiver Data
    const caregiverData = data.parentRoles.caregiver;

    const allianceData = caregiverData.alliance;
    const allianceQuestions = [
        {
            1: 'Thinking of the past month, my care coordinator, Wraparound team and I are all focused on the most important issues for me and my family.',
        },
        {
            2: 'Thinking of the past month, I have been confident that my Wraparound team knows how to help my family and me.',
        },
        {
            3: 'Thinking of the past month, I feel my care coordinator has worked hard to help my family and me.',
        },
        {
            4: 'Thinking of the past month, I feel my care coordinator is genuinely interested in understanding my family and our needs.',
        },
    ];

    const satisfactionData = caregiverData.satisfaction;
    const satisfactionQuestions = [
        {
            1: 'Over the past month, I have been satisfied with the progress my youth and family have made.',
        },
        {
            2: 'Over the past month, I have been satisfied with how the Wraparound process is working for my family and me.',
        },
        {
            3: 'Over the past month, I have been satisfied with the ways my family and I have been included in making decisions.',
        },
        {
            4: 'I would recommend this Wraparound program to other families with similar needs.',
        },
    ];

    const fidelityData = caregiverData.fidelity;
    const fidelityQuestions = [
        {
            1: 'Over the past month, my youth and family have had a team of people who work with us to meet our needs.',
        },
        {
            2: 'My youth and family have a written plan that we helped create and that is focused on meeting our needs.',
        },
        {
            3: ' Thinking of the past month, I feel that I have people who will help me out when I need it.',
        },
        {
            4: 'In the past month, my Wraparound care coordinator has asked me to rate my family’s progress toward meeting its needs.',
        },
    ];

    const outcomesData = caregiverData.outcomes;
    const outcomesQuestions = [
        {
            1: 'Over the past month, my youth has made progress that has positively affected their quality of life. ',
        },
        {
            2: 'Over the past month, participating in Wraparound has helped our family’s quality of life.',
        },
        {
            3: 'I feel confident I can support and care for my child, now and in the future.',
        },
        {
            4: 'Over the past month, my overall mental health has been good.',
        },
    ];

    const feedback = caregiverData.progress.started && caregiverData.feedback;
    const feedbackLow = caregiverData.progress.started && caregiverData.lowFeedback;

    const getSurveyItem = (type, i) => {
        return type.filter(question => question[i])[0];
    };

    const getRows = (dataType, questionType) => {
        return dataType.progress?.[0].value === '1'
            ? dataType.timepoint.map(tp => {
                  return {
                      id: Math.random(),
                      surveyItem: Object.values(getSurveyItem(questionType, +tp.value))[0],
                      score: +tp.response[0].value,
                      completedAt: _format(new Date(tp.response[0].completedAt[0].value), 'M/d/yyyy h:mm aaa'),
                      timepoint: +tp.value,
                  };
              })
            : [];
    };

    const flagged = dataType => {
        const lowScores = [];

        dataType.progress?.[0].value === '1'
            ? dataType.timepoint.map(tp => +tp.response[0].value === 0 && lowScores.push(+tp.response[0].value))
            : [];

        return lowScores.length;
    };

    return {
        youthData: {
            fullName: data.numberAndName,
            progress: data.progress.started,
            feedback: youthFeedback,
            feedbackLow: youthFeedbackLow,
            satisfaction: {
                satisfactionRows: getRows(youthSatisfactionData, youthSatisfactionQuestions),
                avg: +data.messageSummary.satisfactionAvg,
                flagged: flagged(youthSatisfactionData),
            },
            outcomes: {
                outcomesRows: getRows(youthOutcomesData, youthOutcomesQuestions),
                avg: +data.messageSummary.outcomesAvg,
                flagged: flagged(youthOutcomesData),
            },
        },
        caregiverData: {
            fullName: caregiverData.numberAndName,
            progress: caregiverData.progress.started,
            feedbackLow,
            feedback,
            alliance: {
                allianceRows: getRows(allianceData, allianceQuestions),
                avg: +caregiverData.messageSummary.allianceAvg,
                flagged: flagged(allianceData),
            },
            satisfaction: {
                satisfactionRows: getRows(satisfactionData, satisfactionQuestions),
                avg: +caregiverData.messageSummary.satisfactionAvg,
                flagged: flagged(satisfactionData),
            },
            fidelity: {
                fidelityRows: getRows(fidelityData, fidelityQuestions),
                avg: +caregiverData.messageSummary.fidelityAvg,
                flagged: flagged(fidelityData),
            },
            outcomes: {
                outcomesRows: getRows(outcomesData, outcomesQuestions),
                avg: +caregiverData.messageSummary.outcomesAvg,
                flagged: flagged(outcomesData),
            },
        },
    };
};
