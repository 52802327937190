import React from 'react';
import { Formik, Form, Field, CccisdSelect } from 'cccisd-formik';
import notification from 'cccisd-notification';
import Loader from 'cccisd-loader';
import PropTypes from 'prop-types';
import StyleBible from 'cccisd-style-bible';
import Tabs from 'cccisd-tabs';

import { useGetYouthQuery } from '../../../queries';
import IndividualTable from './IndividualTable';
import prepareData from './prepareData.js';

import IconWarning from 'cccisd-icons/warning';

DashboardTabs.propTypes = {
    selectedYouth: PropTypes.object,
};

function DashboardTabs(props) {
    const { selectedYouth } = props;

    const preparedData = prepareData(selectedYouth);

    return (
        <>
            {selectedYouth.parentRoles.caregiver.pawn.pawnId ? (
                <div style={{ marginTop: '20px', marginLeft: '1em' }} className={StyleBible.impactTabs1}>
                    <Tabs
                        tabList={[
                            {
                                name: 'caregiver',
                                title: 'Caregiver',
                                content: <IndividualTable data={preparedData.caregiverData} roleType="caregiver" />,
                            },
                            {
                                name: 'youth',
                                title: 'Youth',
                                content: <IndividualTable data={preparedData.youthData} />,
                            },
                        ]}
                    />
                </div>
            ) : (
                <div style={{ marginLeft: '1em' }}>
                    <IndividualTable data={preparedData.youthData} />
                </div>
            )}
        </>
    );
}

const DomainTables = () => {
    const getYouthQuery = useGetYouthQuery();

    const isLoading = getYouthQuery.isLoading;
    const allQueryDataReturned = getYouthQuery.data;

    if (isLoading) {
        return <Loader loading={isLoading} />;
    }

    if (allQueryDataReturned.data.roles.youthList.length < 1) {
        return (
            <div className="alert alert-danger" style={{ textAlign: 'center' }}>
                <h3>
                    <IconWarning spaceRight />
                    Please roster at least one Youth.
                </h3>
            </div>
        );
    }

    if (allQueryDataReturned) {
        const youthOptions = allQueryDataReturned.data.roles.youthList.map(youth => {
            return {
                key: youth.pawn.pawnId,
                value: youth.pawn.pawnId,
                label: youth.parentRoles.caregiver.pawn.pawnId
                    ? `${youth.numberAndName} / Caregiver : ${youth.parentRoles.caregiver.numberAndName} / Care Coordinator: ${youth.parentRoles.careCoordinator.user.fullName}`
                    : `${youth.numberAndName} / Care Coordinator: ${youth.parentRoles.careCoordinator.user.fullName}`,
            };
        });

        const getYouthById = id => {
            return allQueryDataReturned.data.roles.youthList.find(youth => youth.pawn.pawnId === Number(id));
        };

        return (
            <Formik
                initialValues={{
                    youth: youthOptions[0].value,
                }}
            >
                {({ values }) => (
                    <>
                        <Form>
                            <div className="row">
                                <div className="col-md-6" style={{ marginTop: '1em', marginLeft: '1em' }}>
                                    <Field
                                        name="youth"
                                        component={CccisdSelect}
                                        label="Youth/Young Adult:"
                                        options={youthOptions}
                                    />
                                </div>
                            </div>
                        </Form>
                        <DashboardTabs selectedYouth={getYouthById(values.youth)} />
                    </>
                )}
            </Formik>
        );
    }

    if ([getYouthQuery.isError].some(x => x === true)) {
        return (
            <div>
                {notification({
                    message: `An error has occurred.`,
                    type: 'danger',
                    duration: 8000,
                })}
            </div>
        );
    }
};

export default DomainTables;
