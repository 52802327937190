import React, { useRef } from 'react';
import Modal from 'cccisd-modal';
import axios from 'cccisd-axios';

import CaregiverForm from '../../../../components/Manage/CaregiverForm';

import IconPlus from 'cccisd-icons/plus-circle2';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const AddCaregiverForm = props => {
    const modal = useRef();

    const onSubmit = async values => {
        const response = await axios.post(Boilerplate.route('api.nexus.pawn.store'), {
            role: 'caregiver',
            firstName: values.firstName,
            lastName: values.lastName,
            wrapId: values.wrapId,
            phone: values.phone,
            parentPawns: values.careCoordinatorId,
            group: values.site,
            username: values.wrapId,
        });

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }

        await props.loadData();
        await modal.current.close();
    };

    return (
        <Modal
            ref={modal}
            trigger={
                <button type="button" className="btn btn-primary">
                    <span>
                        <IconPlus spaceRight />
                        Add Caregiver
                    </span>
                </button>
            }
            title="Add New Caregiver"
        >
            <CaregiverForm onSubmit={onSubmit} initialValues={{ site: [], careCoordinator: [] }} />
        </Modal>
    );
};

export default AddCaregiverForm;
