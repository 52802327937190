import React, { useRef } from 'react';
import Modal from 'cccisd-modal';
import axios from 'cccisd-axios';

import YouthForm from '../../../../components/Manage/YouthForm';

import IconPlus from 'cccisd-icons/plus-circle2';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const AddYouthForm = props => {
    const modal = useRef();

    const onSubmit = async values => {
        const response = await axios.post(Boilerplate.route('api.nexus.pawn.store'), {
            role: 'youth',
            wrapId: values.wrapId,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            dateOfBirth: values.dateOfBirth,
            youngAdult: values.youngAdult,
            parentPawns: values.parentPawns,
            group: values.site,
            username: values.wrapId,
        });

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }

        await props.loadData();
        await modal.current.close();
    };

    return (
        <Modal
            ref={modal}
            trigger={
                <button type="button" className="btn btn-primary">
                    <span>
                        <IconPlus spaceRight />
                        Add Youth
                    </span>
                </button>
            }
            title="Add New Youth"
        >
            <YouthForm initialValues={{ site: [], careCoordinator: [], caregiver: [] }} onSubmit={onSubmit} />
        </Modal>
    );
};

export default AddYouthForm;
