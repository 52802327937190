import React from 'react';
import PropTypes from 'prop-types';

import DomainTable from './DomainTable';
import FeedbackBanner from './FeedbackBanner';
import style from './style.css';

import IconHourglass from 'cccisd-icons/hour-glass';

const IndividualTable = props => {
    const { data, roleType } = props;

    return (
        <div style={{ margin: '1em 1em 0 0' }}>
            {data.progress ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                            gap: '1em',
                            justifyContent: 'space-between',
                        }}
                    >
                        <h2 className={style.name}>{data.fullName}</h2>
                    </div>
                    {data.feedbackLow.length > 0 && <FeedbackBanner data={data.feedbackLow} low />}
                    {data.feedback.length > 0 && <FeedbackBanner data={data.feedback} />}
                    {roleType === 'caregiver' && (
                        <DomainTable
                            type="Alliance"
                            data={data.alliance.allianceRows}
                            flagged={data.alliance.flagged}
                            avg={+data.alliance.avg?.toFixed(1)}
                            tooltipText="Alliance items assess caregivers' perceptions of feeling understood and connected to helpers, a critical element of positive change in Wraparound."
                        />
                    )}
                    <DomainTable
                        type="Satisfaction"
                        data={data.satisfaction.satisfactionRows}
                        flagged={data.satisfaction.flagged}
                        avg={+data.satisfaction.avg?.toFixed(1)}
                        tooltipText="Satisfaction items assess caregivers' and youths' perceptions of satisfaction with the response of Wraparound providers and teams."
                    />
                    {roleType === 'caregiver' && (
                        <DomainTable
                            type="Fidelity"
                            data={data.fidelity.fidelityRows}
                            flagged={data.fidelity.flagged}
                            avg={+data.fidelity.avg?.toFixed(1)}
                            tooltipText="Fidelity items are based on the Wraparound Fidelity Index and assess caregivers' perceptions of adherence to basic practice elements of Wraparound."
                        />
                    )}
                    <DomainTable
                        type="Outcomes"
                        data={data.outcomes.outcomesRows}
                        flagged={data.outcomes.flagged}
                        avg={+data.outcomes.avg?.toFixed(1)}
                        tooltipText="Outcome-focused items assess the degree to which the ultimate goals of Wraparound and services are being achieved, such as caregivers' and youth's well-being and connection to supports."
                    />
                </>
            ) : (
                <>
                    <h3>{data.fullName}</h3>
                    <div className="alert alert-info" style={{ textAlign: 'center' }}>
                        <h3>
                            <IconHourglass spaceRight />
                            No responses yet.
                        </h3>
                    </div>
                </>
            )}
        </div>
    );
};

IndividualTable.propTypes = {
    data: PropTypes.object,
    roleType: PropTypes.string,
};

export default IndividualTable;
