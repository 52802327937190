import React from 'react';
import Tabs from 'cccisd-tabs';
import StyleBible from 'cccisd-style-bible';
import Tooltip from 'cccisd-tooltip';

import DomainTables from './DomainTables';
import mockup from './dashboard.png';

import IconConstruction from 'cccisd-icons/construction';

const Dashboard = () => {
    const tabList = [
        {
            name: 'firstTab',
            title: 'Aggregate Data',
            content: (
                <div style={{ marginTop: '1em', marginLeft: '1em' }}>
                    <div className="alert alert-danger" style={{ textAlign: 'center', width: '1180px' }}>
                        <h3>
                            <IconConstruction spaceRight />
                            Under Construction (not actual data)
                        </h3>
                        <p>Select Individual Data above to see dashboards for live Indivdual Respondent Data.</p>
                        <p>
                            Please note this tab is still in development and you are looking at a rough draft of what
                            the first tab may eventually look like.
                        </p>
                    </div>
                    <img src={mockup} alt="Stage 3 mockup" style={{ height: '1080px' }} />
                </div>
            ),
        },
        {
            name: 'secondTab',
            title: (
                <Tooltip title="Shows scores to each survey item for each individual caregiver and/or youth, grouped by domain.">
                    Individual Data
                </Tooltip>
            ),
            content: <DomainTables />,
        },
    ];

    return (
        <div style={{ marginTop: '2rem' }} className={StyleBible.impactTabs1}>
            <Tabs tabList={tabList} />
        </div>
    );
};

export default Dashboard;
